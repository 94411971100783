.subtitulo{
  display: flex; align-items: center; justify-content: center;
}

.subtitulo .max-w-1200{
  height: 100%;
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: start;
  gap: 24px;
}

.subtitulo-text {
  font-size: 28px;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  padding: 0 12px;
  max-width: 580px;
  text-align: start;
  margin: 0;
}

@media screen and (min-width: 890px){

  .subtitulo-text {
    font-size: 42px;
    font-weight: 700;
    font-family: 'Ubuntu', sans-serif;
    padding: 0 64px;
  }
}