.faq-section {
  text-align: left;
  padding: 0 15px;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-section h2 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 95%;
  text-align: center;
  -webkit-text-stroke: 1px black;
  color: white;
  margin: 47px 0 33px;
}

.faq {
  margin: 0;
  width: 100%;
  max-width: 820px;
}

.question {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.question h3 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  max-width: 580px;
  display: none;
}

.question.open p {
  display: block;
}

.toggle {
  font-size: 24px;
  font-weight: 700;
}

.contact-form {
  margin-top: 105px;
  text-align: left;
  max-width: 1400px;
  position: relative;
  width: 100%;
}

.contact-form h2 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 36px;
  font-weight: 700;
  padding: 0 15px;
  position: absolute;
  top: -35px;
  margin: 0;
}

.contact-form .highlight {
  font-weight: 400;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form input,
.contact-form textarea {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  margin: 10px 0;
}

.contact-form button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.contact-form button .arrow {
  margin-left: 10px;
  transition: margin-left 0.3s ease;
}

.contact-form button:hover .arrow {
  margin-left: 15px;
}

.highlight2 {
  color: white;
  -webkit-text-stroke: 1px black;
  position: relative;
}

.highlight2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 5px;
  background-color: #FAA70C;
}

.answer-wrapper {
  overflow: hidden;
  transition: max-height 1.5s ease, opacity 1.5s ease;
}

@media screen and (min-width: 890px) {
  .faq-section h2 {font-size: 106px; margin: 86px 0 49px;}
  .contact-form{margin: 105px auto 46px;}
  .contact-form h2 { padding: 0 72px; font-size: 58px; top: -55px;}
}