.footer {
  background-color: black;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer .max-w-1200 {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  gap: 35px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.footer-logo {
  filter: invert(1);
  width: 150px;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.footer-nav a {
  color: var(--white, #FFF);
  text-align: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.footer-bottom {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  gap: 35px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.footer-bottom nav {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.footer-bottom nav a {
  width: 32px;
  height: 32px;
}

.footer-bottom nav a img {
  width: 100%;
  height: 100%;
}

.footer-bottom h2 {
  color: var(--white, #FFF);
  /* caption/regular */
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

@media screen and (min-width: 890px) {
  .footer .max-w-1200 {
    gap: unset
  }

  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-nav {
    flex-direction: row;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-bottom nav a {
    width: 16px;
    height: 16px;
  }

}






/* .footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  font-family: 'Ubuntu', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  flex-wrap: wrap;
  position: relative;
}

.footer-left {
  flex: 1;
  text-align: left;
}

.footer-logo {
  filter: invert(1); 
  width: 150px; 
}

.footer-middle {
  flex: 2;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}

.footer-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #faa70c; 
}

.social-icon img {
  width: 24px;
  height: 24px;
  transition: filter 0.3s;
}

.social-icon img:hover {
  filter: invert(0) sepia(1) saturate(5) hue-rotate(0deg);}



.footer-bottom {
  text-align: left;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 30px;
}


@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px
  }

  .footer-middle {
    flex-direction: column;
    gap: 10px;
  }

  .footer-right {
    justify-content: center;
  }

  .footer-logo {
    margin-bottom: 20px;
  }
  .footer-bottom {
    margin-top: 20px;
    text-align: center;
  margin-left: 0px;

  }
} */