.roadmap {
  background-color: #000;
  color: #000;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0 60px;
}

.roadmap-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 95%;
  text-align: center;
  -webkit-text-stroke: 1px #fff;
}

.roadmap-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 15px;
}

.roadmap-container::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 15px;
  width: 2px;
  height: calc(100% - 90px);
  background-color: #FAA70C;
  z-index: 0;
}

.roadmap-phase {
  color: #fff;
  border-left: 2px solid transparent;
  padding: 20px 0 20px 20px;
  text-align: left;
  position: relative;
  z-index: 1;
}

.roadmap-phase::before {
  content: '';
  position: absolute;
  left: -7.5px;
  width: 14px;
  height: 14px;
  background-color: #FAA70C;
  border-radius: 50%;
  top: 45px;
  transform: translateY(-50%);
}

.roadmap-phase.current::before {
  width: 20px;
  height: 20px;
  left: -10.5px;
}

.phase-title {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
}

.phase-time {
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.phase-goals {
  list-style-type: none;
  padding: 0;
}

.phase-goals li {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 140%;
}

.phase-goals strong {
  font-weight: 700;
  color: #fff;
}

.status {
  background-color: #FAA70C;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}

.completed {
  background-color: #FAA70C;
  color: #fff;
}

.current .phase-title,
.current .phase-time {
  color: #FAA70C;
}

@media screen and (min-width: 890px) {
    .roadmap-title{font-size: 105px}
    .phase-title{font-size: 40px;}
    .phase-time{font-size: 40px;}
    .phase-goals li{font-size:18px;}
}