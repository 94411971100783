.bullet-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullet-container .max-w-1200 {
  height: 100%;
  max-width: 1200px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: end;
  gap: 24px;
}

.video-bullet-component {
  width: 100%;
  max-width: 400px;
  height: 50vh;
  max-height: 600px;
}

.video-bullet-component video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bullet-list-component {
  list-style-type: circle;
  color: white;
  margin: 0;
  box-sizing: border-box;
  padding: 0 12px;
  max-width: 580px;
}

.bullet-list-component .bullet-item {
  margin-left: 20px;
  text-align: left;
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 800;
  font-style: italic;
  margin-bottom: 12px;
}
@media screen and (min-width: 490px){
  .bullet-container .max-w-1200 {
    align-items: center;
    flex-direction: row;
    
  }
}

@media screen and (min-width: 890px){
  .bullet-list-component .bullet-item {
    font-size: 24px;
  }
}