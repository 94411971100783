.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 90vh;
}

.max-w-1200 {
  height: 100%;
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: end;
  gap: 24px;
}

.video-wrapper {
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 70%;
  overflow: visible;
  z-index: -1;
  pointer-events: none;
  max-width: 600px;
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.textContainer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  justify-content: end;
  padding: 0 15px;
  max-width: 673px;
}

.header-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 100px;
  font-weight: 500;
  line-height: 83px;
  text-align: right;
  letter-spacing: -0.2vw;
  margin: 0;
}

.white-high {
  font-family: 'Ubuntu', sans-serif;
  font-size: 47px;
  font-weight: bold;
  line-height: 100%;
  text-align: right;
  color: white;
  -webkit-text-stroke: 1px #8D8D8D;
}

.subtitle-container {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.subtitle-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 28.5px;
  text-align: right;
  margin: 0;
  width: 100%;
  /* Smaller margins */
}

.keyword-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 28.5px;
  text-align: right;
  font-style: italic;
}

.rw-words {
  display: block;
  position: relative;
  font-weight: bold;
  height: 34px;
  width: 100%;
}

.rw-words-1 span {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  opacity: 0;
  color: black;
  animation: rotateWord 12s linear infinite 0s;
  left: 0;
}

.rw-words-1 span:nth-child(2) {
  -webkit-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
  color: black;
}

.rw-words-1 span:nth-child(3) {
  -webkit-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
  color: black;
}

.rw-words-1 span:nth-child(4) {
  -webkit-animation-delay: 9s;
  -ms-animation-delay: 9s;
  animation-delay: 9s;
  color: black;
}

.rw-words-1 span:nth-child(5) {
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
  color: black;
}

.rw-words-1 span:nth-child(6) {
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  -o-animation-delay: 15s;
  -ms-animation-delay: 15s;
  animation-delay: 15s;
  color: black;
}

@-webkit-keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -ms-transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -ms-transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.scroll-button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
}

.scroll-button:hover {
  background-color: #333;
}

.vector-icon {
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
  /* Adjust size as needed */
  margin-left: 10px;
  /* Adjust spacing as needed */
  /* filter: invert(100%); */
  /* Make the icon white */
}


@media screen and (min-width: 690px) {

  .subtitle-container { gap: 24px;}
  .rw-words {height: 50px;}
  .keyword-text {font-size: 42px; line-height: 42.5px; margin-right: 150px;}
  .header-text {
    font-size: 130px;
    line-height: 127px;
  }

  .white-high {
    font-size: 80px;
    line-height: 80px;
  }

  .video-wrapper {
    height: 100%;
    top: unset;
  }

  .scroll-button {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .textContainer {
    justify-content: center;
    gap: 24px
  }
}