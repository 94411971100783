@import url('https://fonts.googleapis.com/css2?family=Anton&family=Signika:wght@300..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.App {
  text-align: center;
  
}
body {
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Ubuntu', sans-serif;
  cursor: url(../assets/icons/AUREA-ICON.svg);
}

.App {
  width: 100%;
  height: 100%;
  cursor: url(../assets/icons/AUREA-ICON.svg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.margin-icon {
  margin-top: 1px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Apply the font globally or to specific elements */
body {
  font-family: 'Schibsted Grotesk', sans-serif;
}

.schibsted-grotesk-regular {
  font-family: 'Schibsted Grotesk', sans-serif;
  font-weight: 500;
  font-size: 11.33px;
  line-height: 13.99px;
}

.schibsted-grotesk-bold {
  font-family: 'Schibsted Grotesk', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.28px;
}

/* Black Background */
.black-background{
  background-color: black;;
}

.spacer-60{
  height: 60px;
}

.spacer-30{
  height: 30px;
}
