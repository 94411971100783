.section-two {
  position: relative;
  width: 100vw;
  height: 112vh;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
}

.section-two .max-w-1200{
  height: 100%;
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 24px;
  z-index: 2;
}

.section-two .background-vid {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw; 
  height: 100vh;
  object-fit: cover;
  z-index: 1;
  filter: brightness(0.5);
}

.title-sec-two {
  font-family: 'Ubuntu', sans-serif;
  font-size: 55px;
  font-weight: 500;
  line-height: 95%;
  text-align: center;
  -webkit-text-stroke: 1px #fff;
  margin-bottom: 20px;
  margin-top: 0;
  padding: 0 15px;
}

.body-text {
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  line-height: 140%;
  text-align: left;
  padding: 0 15px;
  margin: 0 0 100px 0;
}

@media screen and (min-width: 690px){
  .section-two {height: 97vh}
}
@media screen and (min-width: 890px){
  .section-two {height: 115vh}
    .title-sec-two {font-size: 106px;}
    .body-text { font-size: 50px;}
}


@media screen and (min-width: 1400px){
  .section-two{
    height: 100vh;
  }
}