.intro-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 60vh;
  max-height: 620px;
  cursor: url(http://localhost:3000/static/media/isotipo.a3a62b2….svg) 16 16, auto;
}

.intro-form .max-w-1200 {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: -1;
  pointer-events: none;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}

.intro-form-image-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: -1;
  pointer-events: none;
  max-width: 600px;
}

.intro-form-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.intro-form-content {
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 15px;
  max-width: 673px;
  box-sizing: border-box;
  width: 100%;
}

.intro-form-title {
  font-family: 'Ubuntu', sans-serif;
    font-size: 45px;
    width: 100%;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    letter-spacing: -0.2vw;
    margin: 0;
    max-width: 983px;
    box-sizing: border-box;
}


@media screen and (min-width: 590px) {
  .intro-form-title {
    font-size: 80px;
    line-height: 127px;
    margin-left: 24px;
    white-space: nowrap;
  }
}

@media screen and (min-width: 890px) {
  .intro-form-title {
    font-size: 120px;
    line-height: 127px;
    margin-left: 24px;
  }
}