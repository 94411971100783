.carrousel-artists {
  position: relative;
  width: 100vw; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carrousel-artists .max-w-1200 {
  width: 100%;
  max-width: 1440px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carrusel-container{
  width: 100%;
  position: relative;
  max-width: 850px;
  margin-bottom: 60px;
}

.splide__arrows{
  height: 50px;
  position: relative;
  width: 100vw;
  margin-bottom: 24px;
}

.splide__arrow {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  pointer-events: all;
}


.splide__arrow img {
  width: 40px;
  height: auto;
}

.splide__arrow--prev {
  right: calc(1em + 40px);
  left: unset;
}

.splide{
  width: 100%;
}

.tarjeta {
  background-color: #0D0D0D;
  border: 2px solid #FAA70C;
  border-radius: 10px;
  color: white;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 32px;
}

.icon-tarjeta{
  width: 32px;
  height: 32px;
  position: absolute;
  right: 24px;
  top: 24px;
}

.tarjeta h2{
  font-size: 24px;
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  text-align: start;
  max-width: 225px;
}

.tarjeta p{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  text-align: start;
  max-width: 500px;
}

/* Estilos para la paginación */
.splide__pagination {
  margin-top: 12px;
  bottom: unset;
}

.splide__pagination__page {
  background: white;
  opacity: 0.7;
}

.splide__pagination__page.is-active {
  background: white;
  opacity: 1;
}

.intro-form-p{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  max-width: 580px;
}

@media screen and (min-width: 890px){

  .tarjeta h2{
    font-size: 30px;
    max-width: 425px;
  }

  .tarjeta p{
    font-size: 22px;
  }

  .intro-form-p{
    font-size: 26px;
  }
}