.parrafo-normal{
  display: flex; align-items: center; justify-content: center;
}

.parrafo-normal .max-w-1200{
  height: 100%;
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: start;
  gap: 24px;
}

.parrafo-normal-text {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  text-align: start;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  max-width: 580px;
  margin: 0;
}


@media screen and (min-width: 890px){

  .parrafo-normal-text {
    font-size: 21px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    padding: 0 64px;
    max-width: 780px;
  }
}