.section-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 170vh;
  background-color: black;
  position: relative;
  padding: 100px 0 0;
}
.section-one .max-w-1200{
  height: 100%;
  max-width: 1400px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: start;
  gap: 24px;
}

.video-wrapper2 {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  filter: brightness(0.4);
  overflow:hidden;
}

.background-video{
  width: 100%;
  height: 100%;
  object-fit:contain;
}

.content {
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 473px;
}

.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  height: 75vh;
  box-sizing: border-box;
  padding: 0 15px;
  /* Space between text-boxes */
}

.title-one,
.title-two {
  font-family: 'Ubuntu', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 135%;
  text-align: left;
  color: white;
}

.text-one,
.text-two {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  color: white;
}

.join-button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: #FAA70C;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: inherit;
  text-decoration: none;
  text-transform: uppercase;
}

.join-button p {
  margin: 0;
}

.vector-icon {
  margin-left: 10px;
}

.highlight {
  color: black;
  -webkit-text-stroke: 1px white;
  position: relative;
}

.highlight::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  /* Adjust to move the underline closer to the text */
  width: 100%;
  height: 5px;
  background-color: #FAA70C;
  z-index: -1;
}


@media screen and (min-width: 690px){
  .section-one {padding: 100px 0;}
  .title-one, .title-two{font-size: 76px; line-height:95%; width: 140%;}
  .text-one, .text-two {font-size: 18px;}
  .video-wrapper2{left: 50%; width: 50%; overflow: visible;}
}

@media screen and (min-width: 1140px){
  .video-wrapper2{filter: unset;}
}