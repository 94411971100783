.intro-section {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 0 0;
}

.marquee-wrapper {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
}

.marquee {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  display: inline-block;
  font-family: 'Ubuntu', sans-serif;
  font-size: 42px;
  font-weight: 700;
  white-space: nowrap;
}

.marquee span {
  display: inline-block;
  animation: marquee linear infinite;
  animation-duration: var(--animation-duration);
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.intro-paragraph {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  max-width: 1400px;
}

.intro-text {
  margin: 40px 0 20px;
  text-align: left;
  margin-right: auto;
}

.intro-paragraph p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}

.learn-more-button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background-color: transparent;
  border: 2px solid #000;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.5s, color 0.5s;
  width: 100%;
}

.learn-more-button img {
  margin-left: 15px;
  width: 12px;
  height: 12px;
  transition: filter 0.5s, margin-left 0.3s ease;
}

.learn-more-button:hover {
  background-color: black;
  color: white;
}

.learn-more-button:hover img {
  filter: invert(1);
}

@media screen and (min-width: 890px) {
  .marquee {
    font-size: 48px;
  }
  .intro-paragraph {
    flex-direction: row;
    gap: 43px;
    justify-content: space-between;
  }
  .learn-more-button {
    max-width: 330px;
  }
}

    