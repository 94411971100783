.video-section {
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 62px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
  max-width: 1200px;
  max-height: 900px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 12px;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
