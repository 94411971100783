/* ContactForm.css */
.contact-form-card {
  background-color: #f2f2f2;
  padding: 80px 15px;
  margin: 0;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  max-width: 900px
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  /* Remove border */
  border-bottom: 2px solid #ccc;
  /* Add only bottom border */
  background: none;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  /* Ensure padding doesn't affect width */
}

.input-group input:focus,
.input-group textarea:focus {
  border-bottom: 2px solid #000;
  outline: none;
}

.input-group textarea {
  resize: none;
}

.error {
  color: red;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.submit-button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  /* White text color */
  background-color: transparent;
  border: 2px solid #000;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.5s, color 0.5s;
  float: left;
  /* Align the button to the left */
  max-width: 7rem;
}

.submit-button img {
  margin-left: 10px;
  /* Add margin left */
  width: 12px;
  /* Make the arrow smaller */
  height: 12px;
  /* Make the arrow smaller */
  filter: invert(1);
  /* Invert color to black */
  transition: filter 0.5s, margin-left 0.3s ease;
}

.submit-button:hover {
  background-color: #FAA70C;
  /* Change to gold on hover */
  color: #fff;
}
.contact-form-artists{
  width: 100%;
  display: flex;
  justify-content: center;  
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -1px;
}

.container-form{
  background-color: #403E3F;
  text-align: left;
  max-width: 1400px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-form .contact-form-card{
  background-color: unset;
  width: 100%;
  max-width: 800px;
}

.contact-form-artists::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #0d0d0d;
  z-index: -1;
}

.container-form .input-group input,
.input-group textarea {
  color: white;
}

.container-form .input-group input::placeholder,
.input-group textarea::placeholder{
  color: white;
}

.radio-group{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-group label{
  display: flex;
  flex-direction:row;
  gap: 12px;
  padding-right: 12px;
  color: white;
}

@media screen and (min-width: 890px) {
  .contact-form-card{padding: 112px 15px;}
}