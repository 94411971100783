/* gestion de headers mobile/desktop */
.desktop-header {
  display: none;
}

.mobile-header {
  display: flex;
}

@media screen and (min-width: 1024px) {
  .mobile-header {
    display: none;
  }

  .desktop-header {
    display: flex;
  }
}

/* desktop-header */
.desktop-header {
  flex-direction: column;
  height: 10vh;
  max-height: 150px;
  justify-content: space-between;
  align-items: center;
}

.desktop-header .max-w-1200 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(10vh - 1px);
  max-width: 1400px;
  box-sizing: border-box;
  padding: 0 15px;
  align-items: center;
}

.desktop-header .divisor {
  height: 1px;
  background: black;
  width: calc(100% - 15px);
  max-width: 1400px;
}

.desktop-header .logo img {
  height: 28.02px;
}

.desktop-header .desktop-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  max-width: 720px;
}

.desktop-header .desktop-nav a {
  text-decoration: none;
  color: black;
  font-family:'Ubuntu', sans-serif;;
  font-size: 12px;
  font-weight: 500;
  line-height: 13.79px;
  text-align: left;
  position: relative;
  transition: all 0.3s ease;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.desktop-header .desktop-nav a:hover {
  font-size: 14px;
  font-weight: 700;
}

.desktop-header .desktop-nav a p:hover::after {
  content: '';
  width: 100%;
  height: 4px;
  background-color: #FAA70C;
  display: block;
}

.desktop-header .social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80px;
}

.desktop-header .social-links a {
  width: 24px;
  height: 24px;
}

.desktop-header .social-links a img {
  filter: invert(1);
  width: 100%;
  height: 100%;
}

/* mobile header */
.logo-mobile{
  width: 38px;
  height: 38px;
}

.mobile-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  padding: 12px;
  height: 10vh;
}

.mobile-header .hamburger-button {
  background-color: transparent;
  border: none
}

.modal-header {
  position: absolute;
  top: 0;
  z-index: 10;
  background: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header .close-button {
  background-color: transparent;
  border: none;
  color: var(--white, #FFF);
  font-family: 'Ubuntu', sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  position: absolute;
  right: 15px;
  top: 12px;
}

.modal-header .modal-nav {
    display: flex;
    height: 396px;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: 112px;
}

.modal-header .modal-nav .nav-link{
  color: var(--white, #FFF);
/* Titulo/5_small */
font-family: 'Ubuntu', sans-serif;
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: 95%; /* 28.5px */
text-transform: uppercase;
text-decoration: none;
}

.modal-header .social-links{
  position: absolute;
  bottom: 112px;
  width: 96px;
  display: flex;
  justify-content: space-between;
}

.modal-header .social-links .social-link{
  width: 28px;
  height: 28px;
}

.modal-header .social-links .social-link img{
  width: 100%;
  height: 100%;
}







/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background: white;
  z-index: 1000;
  font-family: 'Ubuntu', sans-serif;
  border-bottom: none;
}

.header-separator {
  height: 1px;
  background-color: black;
  width: calc(100% - 10%);
  margin: 0 5%;
}

.header .logo img {
  height: 40px;
}

.header nav a {
  text-decoration: none;
  color: black;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 13.79px;
  text-align: left;
  position: relative;
  transition: all 0.3s ease;
}

.header nav a:hover {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.09px;
}

.header nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #FAA70C;
  left: 0;
  bottom: -2px;
  transition: width 0.3s;
}

.header nav a:hover::after {
  width: 100%;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000;
} */