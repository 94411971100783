.section-three {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  z-index: 3;
  padding: 50px 0;
}

.top-container {
  display: flex;
  width: 100vw;
  flex-direction: column-reverse;
  max-width: 1400px;
  padding: 0 15px;
  box-sizing: border-box;
}

.video-container-three {
  max-width: 100vw;
  overflow: hidden;
  height: 60vh;
  margin: 32px 0 48px;
}

.video-container-three video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-container-three {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bullet-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bullet-item {
  text-align: left;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-style: italic;
}

.section-title-three {
  font-family: 'Ubuntu', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 140%;
  margin: 20px 0;
  max-width: 360px;
}

.outlined-text {
  color: white;
  -webkit-text-stroke: 1px black;
}

.section-body-three {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.h-50 {
  display: none;
}

.intro-text-three {
  display: flex;
  flex-direction: column;
  padding: 32px 15px;
  max-width: 1400px;
  width: 100vw;
  box-sizing: border-box;
  gap: 5px
}

.intro-text-three .join-us-title{
  font-family: 'Ubuntu', sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 95%;
    text-align: center;
    -webkit-text-stroke: 1px #000;
    color: white;
    text-transform: uppercase;
}

.intro-text-three p b{
  font-size: 24px;
  max-width: 750px;
}

.intro-text-three p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: black;
  text-align: center;
  margin: 0;
}

.bottom-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
  box-sizing: border-box;
  padding: 0 15px;
  max-width: 1400px;
  position: relative;
}

.cta-buttons-three {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.cta-buttons-three .cta-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.5s, color 0.5s;
  color: black;
  width: 100%;
  text-decoration: none; /* Añadido para quitar el subrayado del enlace */
}

.cta-buttons-three .cta-button .m-w-530 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 530px;
}

.cta-buttons-three .cta-button p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
  font-weight: 700;
  max-width: 250px;
  text-align: start;
}

.cta-buttons-three .cta-button img {
  margin-left: 10px;
  transition: filter 0.5s;
  width: 28px;
}

.cta-buttons-three .cta-button:hover {
  background-color: black;
  color: white;
}

.cta-buttons-three .cta-button:hover img {
  filter: invert(1);
}

.separator {
  height: 2px;
  background-color: black;
  width: 100%;
}

.cta-image-three {
  position: absolute;
  right: 0;
  width: 407px;
  height: 493px;
  top: 0;
  display: none;
}

.cta-image-three img {
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.3s ease;
}

@media screen and (min-width: 890px) {
  .section-title-three {
    font-size: 76px;
    line-height: 95%;
    max-width: unset;
  }

  .section-body-three {
    font-size: 18px;
  }

  .rotating-text-three {
    font-size: 25px;
    height: 75px;
  }

  .intro-text-three {
    font-size: 18px;
  }

  .intro-text-three .join-us-title{
    font-size: 105px;
  }

  .intro-text-three p {
    font-size: 28px;
  }
  .intro-text-three p b{
    font-size: 32px;
  }

  .top-container {
    flex-direction: row;
    align-items: center;
    margin-top: -10px;
  }

  .bullet-item {
    font-size: 24px;
  }

  .video-container-three {
    width: 50%;
    height: 90vh;
    margin: unset;
  }

  .text-container-three {
    width: 60%;
    gap: 32px;
  }

  .h-50 {
    display: block;
    width: 100vw;
    height: 50px;
  }

  .bottom-container {
    margin: 30px 0 120px;
  }

  .cta-image-three {
    display: block;
  }
}